import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

let store = new Vuex.Store({
  state: {
    accountAlias: '',
    accountType: '',
    ecoAccountId: '',
    email: '',
    enterpriseName: '',
    phone: '',
  },
  mutations: {
    setAccountId(state, ecoAccountId){
      state.ecoAccountId = ecoAccountId
    },
    setAccountAlias(state, accountAlias){
      state.accountAlias = accountAlias
    },
    setAccountType(state, accountType){
      state.accountType = accountType
    },
    setEmail(state, email){
      state.email = email
    },
    setEnterpriseName(state, enterpriseName){
      state.enterpriseName = enterpriseName
    },
    setPhone(state, phone){
      state.phone = phone
    },
  },
  actions: {
  },
  modules: {
  },
  getters: {
  }
})

export default store;
