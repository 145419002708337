/**
 * 程序名：Vue全局配置
 * 功能：Vue全局配置
 */
import VueLuckyCanvas from '@lucky-canvas/vue';
import ElementUI from 'element-ui';
import {
  Button,
  Dialog,
  Field,
  Form,
  Toast
} from 'vant';
import 'vant/lib/index.css';
import 'vant/lib/index.less';
import Vue from 'vue';
import VueClipboard from 'vue-clipboard2';
import VXETable from 'vxe-table';
import 'vxe-table/lib/style.css';
import 'xe-utils';
import globalVariable from './api/globalVariable.js';
import App from './App.vue';
import router from './router';
import store from './store';
import './style/element-variables.scss';
import md5 from './utils/md5';
import {
  VueAxios
} from './utils/request';

Vue.config.productionTip = false
Vue.prototype.$g = globalVariable
Vue.use(VueAxios)
  .use(ElementUI, {
    size: 'small',
    zIndex: 500
  })
  .use(VueClipboard)
  .use(md5)
  .use(VXETable)
  .use(Dialog)
  .use(Form)
  .use(Field)
  .use(Button)
  .use(Toast)
  .use(VueLuckyCanvas)

Vue.prototype.validForbid = (value) => {
  value = value.replace(/,/g, '').replace(/\s/g, '');
  return value;
}

router.beforeEach((to, from, next) => {
  if(to.meta.title) {
    document.title = to.meta.title
  }
  next()
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')