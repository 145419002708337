import axios from 'axios';
import ElementUI from 'element-ui';
import {
  VueAxios
} from './axios';
import g from '../api/globalVariable';

// 创建 axios 实例
const request = axios.create({
  // API 请求的默认前缀
  baseURL: '',
  timeout: 8000 // 请求超时时间
})

// 拦截器
request.interceptors.request.use(config => {
  const token = sessionStorage.getItem('LocalToken'); // 问卷系统的token
  // 如果token存在，则每个请求携带token
  if (token) {
    config.headers['token'] = token
  }
  return config
}, err => {
  return Promise.reject(err)
})

// response interceptor
request.interceptors.response.use((response) => {
  return response.data
}, err => {
  if (!err.response) {
    ElementUI.Message({
      type: 'error',
      duration: 2000,
      offset: 100,
      message: `接口响应失败，请刷新浏览器重试。错误原因：${err}`,
    })
    return Promise.resolve(err)
  }
  switch(err.response.status) {
    case 401:
      ElementUI.Message({
        type: 'error',
        duration: 3000,
        offset: 100,
        message: '登录超时，请重新登录'
      });
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('LocalToken')
      sessionStorage.removeItem('user')
      sessionStorage.removeItem('store');
      const redirect_uri = encodeURIComponent(g.AUTH_INDEX);
      location.href = `${g.AUTHORIZE_NET}?response_type=code&client_id=${g.CLIENT_ID}&redirect_uri=${redirect_uri}&state=authapi_index`;
      break;
    case 500:
      ElementUI.Message({
        type: 'error',
        duration: 3000,
        offset: 100,
        message: '服务器内部错误'
      });
      break;
    default:
      return Promise.reject(err);
  }
  return err
})

const installer = {
  vm: {},
  install(Vue) {
    Vue.use(VueAxios, request)
  }
}

export default request

export {
  installer as VueAxios,
  request as axios
};

