// 全局参数 测试服
const HOST = document.location.origin;
// 市场活动访问问卷接口
const CLIENT_ID_MARKET_LIST = ['mservice_test', 'mservice_prod'];
const CLIENT_ID = process.env.VUE_APP_CLIENT_ID;
const AUTHORIZE_NET = process.env.VUE_APP_AUTHORIZE_NET;

const AUTH_INDEX = `${HOST}/index`;

export default {
  HOST,
  CLIENT_ID,
  AUTHORIZE_NET,
  AUTH_INDEX,
  CLIENT_ID_MARKET_LIST,
}
