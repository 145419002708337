import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'Base',
    component: () => import('@/components/Base'),
    meta: {
      title: '华讯调研问卷系统'
    },
    children: [
      {
        path: '/',
        redirect: '/index',
        meta: {
          title: '设计问卷'
        },
      },
      // {
      //   path: '/index',
      //   name: 'Index',
      //   component: () => import('@/views/index/Index.vue'),
      //   meta: {
      //     title: '华讯调研问卷系统'
      //   },
      // },
      {
        path: '/paper',
        name: 'Home',
        component: () => import('@/views/admin/Home.vue'),
        meta: {
          title: '设计问卷'
        },
      },
      {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/index/Login.vue'),
        meta: {
          title: '登录'
        },
      },
    ]
  },
  {
    path: '/index',
    name: 'Index',
    component: () => import('@/views/index/Index.vue'),
    meta: {
      title: '华讯调研问卷系统'
    },
  },
  {
    path: '/preview/:id',
    name: 'Preview',
    component: () => import('@/views/admin/Preview.vue'),
    meta: {
      title: '预览问卷'
    },
  },
  {
    path: '/answer',
    name: 'Display',
    component: () => import('@/views/user/Display.vue'),
    meta: {
      title: '华讯调研问卷系统'
    },
  },
  {
    path: '/thankyou',
    name: 'ThankYou',
    component: () => import('@/views/user/ThankYou.vue'),
    meta: {
      title: '华讯调研问卷系统'
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router